<template>
  <div class="main">
    <Header />
    <div class="content">
      <router-view></router-view>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
export default {
  name: 'main',
  components: { Header, Footer }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  width: 1911px;
  flex-shrink: 0;
  background: #FF9315;
  background-image: url("~@/assets/images/main-bg-top.png"), url("~@/assets/images/main-bg-left.png");
  background-position: top -70px left -120px, top 143px right -300px;
  background-size: 1203px 213px, 631px 721px;
  background-repeat: no-repeat, no-repeat;
}
</style>
