<template>
  <div class="header-box">
    <div class="header">
      <div class="logo" @click.stop="$router.push('/')">
        <img src="@/assets/logo.png" alt="">
      </div>
      <div class="menu">
        <div :class="`nemu-item ${(new RegExp('ordinals')).test(hash) ? 'acion' : ''}`"  @click.stop="$router.push('/ordinals')">
          <div class="z">
            Ordinals
          </div>
        </div>
        <div :class="`nemu-item ${(new RegExp('bitcoinPets')).test(hash) ? 'acion' : ''}`" @click.stop="$router.push('/bitcoinPets')">
          <div class="z">
            Bitpets
          </div>
        </div>
        <div :class="`nemu-item ${(new RegExp('roadmap')).test(hash) ? 'acion' : ''}`" @click.stop="$router.push('/roadmap')">
          <div class="z">
            Roadmap
          </div>
        </div>
        <div class="nemu-item" @click.stop="link('https://twitter.com/BavoBTC')">
          <div class="z">
            Twitter
          </div>
        </div>
        <div class="nemu-item" @click.stop="link('http://discord.gg/bavo')">
          <div class="z">
            Discord
          </div>
        </div>
        <div class="nemu-item" @click.stop="link('https://unisat.io/market/brc20?tick=BAVO')">
          <div class="z">
            Buy $BAVO
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router';
export default {
  name: 'Header',
  setup() {

    const link = (e) => {
      window.open(e, "_blank");
    }

    const hash = ref('')
    const router = useRouter()
    // 监听当前路由的name变化
    watch(
      () => router.currentRoute.value.name,
      (newRouterName) => {
        hash.value = newRouterName
      },
      { immediate: true }
    )

    return {
      link,
      hash
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header-box {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 1911px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0);
  margin: 46px 0;
  z-index: 400;
}
.header-box-mobail {
  width: 1911px;

}

.header {
  box-sizing: border-box;
  width: 1911px;
  padding: 0 46px 0 46px;
  height: 136px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  width: 100px;
  background: #000;
  position: relative;
  border-top: 5px solid #000;
  border-left: 5px solid #000;
  cursor: pointer;
  margin-left: 32px;
}
.logo img {
  width: 100px;
  position: relative;
  z-index: 2;
}
.logo::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-right: 8px solid #000;
  border-bottom: 8px solid #000;
  top: 0px;
  left: 0px;
}
.menu {
  display: flex;
  color: #000;
  font-family: 'TrueLogoG-Ultra';
  font-size: 17.95px;
  font-weight: 600;
}
.menu .nemu-item {
  position: relative;
  padding: 23.37px 31.47px;
  padding: 4.5px 8.89px 10.13px 5.84px;
  background-color: #fff;
  border: 5px solid #000;
  margin-right: 42.67px;
  cursor: pointer;
}
.menu .nemu-item .z {
  position: relative;
  z-index: 2;
}
.menu .nemu-item::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-right: 8px solid #000;
  border-bottom: 8px solid #000;
  top: 4px;
  left: 5px;
}
.menu .acion {
  transform: translateY(8px);
}
.menu .acion::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  opacity: 0;

}
</style>
