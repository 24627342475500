<template>
  <div class="footer">
    <div class="music">
      <div class="z">
        <div>Music</div>
        <img @click.stop="setIsPlay" :src="isPlay % 2?pause:stop" alt="">
      </div>
      <audio
        ref="bravoRef"
        :src="bravo"
        controls
        loop
       >
         浏览器不支持音频播放。
       </audio> 
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import bravo from '../assets/audio/Bravo.wav'
import stop from '@/assets/images/stop.svg'
import pause from '@/assets/images/pause.png'
export default {
  name: 'Footer',
  setup() {
    const bravoRef = ref(null)
    const isPlay = ref(0)

    const setIsPlay = () => {
      isPlay.value = isPlay.value + 1
      if(isPlay.value % 2) {
        bravoRef.value.play()
      } else {
        bravoRef.value.pause()
      }
    }

        

    return {
      bravo,
      bravoRef,
      setIsPlay,
      isPlay,
      stop,
      pause
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
  position: relative;
}
.footer audio {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;
}
.music {
  position: absolute;
  bottom: 37.5px;
  left: 77.5px;
  padding: 14.72px 27px 26.28px 16.16px;
  background-color: #F3D554;
  border: 5px solid #000;
  font-size: 34.64px;
  font-weight: 600;
  cursor: pointer;
}
.music .z {
  position: relative;
  z-index: 2;
  display: flex;

}
.music img {
  margin-left: 12.84px;
  width: 21px;
  height: 51.95px;
}
.music::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-right: 8px solid #000;
  border-bottom: 8px solid #000;
  top: 5px;
  left: 5px;
  z-index: 1;
}
</style>
